export const spriteOptions = [
  { id: 'accordion', label: 'Accordion' },
  { id: 'banjo', label: 'Banjo' },
  { id: 'bass_drum', label: 'Bass Drum' },
  { id: 'bassoon', label: 'Bassoon' },
  { id: 'berimbau', label: 'Berimbau' },
  { id: 'cello', label: 'Cello' },
  { id: 'clarinet', label: 'Clarinet' },
  { id: 'conga', label: 'Conga' },
  { id: 'cymbals', label: 'Cymbals' },
  { id: 'electric_guitar', label: 'Electric Guitar' },
  { id: 'french_horn', label: 'French Horn' },
  { id: 'guitar', label: 'Guitar' },
  { id: 'harmonica', label: 'Harmonica' },
  { id: 'harp', label: 'Harp' },
  { id: 'maracca', label: 'Maracca' },
  { id: 'melodica', label: 'Melodica' },
  { id: 'pan_flute', label: 'Pan Flute' },
  { id: 'piano', label: 'Piano' },
  { id: 'recorder', label: 'Recorder' },
  { id: 'saxophone', label: 'Saxophone' },
  { id: 'shamisen', label: 'Shamisen' },
  { id: 'sitar', label: 'Sitar' },
  { id: 'snare_drum', label: 'Snare Drum' },
  { id: 'tabla', label: 'Tabla' },
  { id: 'tambourine', label: 'Tambourine' },
  { id: 'theremin', label: 'Theremin' },
  { id: 'trombone', label: 'Trombone' },
  { id: 'trumpet', label: 'Trumpet' },
  { id: 'tuba', label: 'Tuba' },
  { id: 'xylophone', label: 'Xylophone' },
]
